@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;900&display=swap");

div {
  z-index: inherit;
}

.ant-radio-disabled + span, .ant-checkbox-disabled + span{
  opacity: 0.75;
  color: inherit;
}

::-webkit-scrollbar {
  display: none;
}

.ant-picker-calendar {
  background: #e0e4e4;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  background: #e0e4e4;
}

.ant-form,
.ant-page-header-heading {
  max-width: 768px;
}

.ant-form label {
  font-size: 16px;
}

.flip {
  width: 100%;
  opacity: 1;
  transition: width 0.3s, opacity 0.2s ease-in;
}

.flip.active {
  width: 0%;
  opacity: 0;
  transition: width 0.3s, opacity 0.2s ease-out;
}

body {
  min-height: -webkit-fill-available;
  zoom: 100%;
  font-weight: 400;
  font-size: 16px;
  /* font-family: greycliff-cf, sans-serif; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

p {
  margin-bottom: 0;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  border-radius: 100%;
}

.ant-select-selector {
  border-radius: 3px !important;
}

.ant-input-prefix {
  font-weight: 600;
}

.pac-container {
  border-radius: 3px;
  box-shadow: none;
}

.hdpi.pac-logo:after {
  background-image: none;
}

h1,
.ant-page-header-heading-title {
  font-size: 24px;
  margin-right: 0;
  font-weight: 400;
}

.ant-page-header-heading-title {
  padding-right: 12px;
}

h2 {
  font-size: 20px !important;
  font-weight: 400;
}

h3,
.ant-form-item-label label {
  font-size: 16px;
  font-weight: 400;
}
.ant-form-item-label {
  text-align: inherit;
}
.ant-form-item-label > label::after {
  content: none;
}

.message-section::-webkit-scrollbar {
  display: none;
}

.ant-page-header-heading-extra {
  margin: 0;
}

html,
#root {
  /* height: 100%; */
  /* height: -webkit-fill-available; */
  width: -webkit-fill-available;
}

.App {
  text-align: center;
}

.ant-space {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#input {
  min-width: 48px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-page-header-heading {
  align-items: center;
}

.cstm-wrapper {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
}

.header-details {
  display: flex;
}

.sub-header-text {
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
}

/* .message-form {
  position: sticky;
  bottom: 0;
} */

.ant-form-item-label > label {
  height: auto;
}


.ant-form-item-label > label.ant-form-item-required:before {
  content: unset;
}

.ant-btn:hover, .ant-btn:focus,.ant-btn:active {
  text-decoration: inherit;
  color: inherit;
  border-color: inherit;
  background: inherit;
}


/* 
@media (min-width: 425px) {
  .site-page-header {
    border-image: linear-gradient(130deg, #3a3960 20%, #d56994 70%, #eb9a92 100%);
    border-image-slice: 1;
    border-bottom: 1px solid;
    z-index: 3;
  }
} */

/* .ant-steps-vertical {
  display: flex;
  flex-direction: row;
}

.ant-steps-item-container {
  outline: none;  
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.ant-row {
  max-width: 768px;
}

.ant-rate-star-first,
.ant-rate-star-second {
  color: rgba(0, 0, 0, 0.25);
}

.menu-item {
  border-radius: 3px;
}
.ant-card-grid {
  padding: 8px;
  border-radius: 3px;
  box-shadow: none;
  text-align: center;
}

.menu-item-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.soco-page {
  max-width: 100vw;
  padding: 32px 16px 16px 16px;
}

/* .soco-page{
} */


.tight.ant-form-item, .tight.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0;
  margin-bottom: 8px;
}
/* .ant-form-item-with-help .ant-form-item-explain {
} */

.ant-input-group .ant-input {
  text-align: left;
}

.message-section {
  padding: 10px 10px 30px;
  /* height: calc(100vh - 150px); */
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ant-divider-horizontal.ant-divider-with-text {
  font-weight: 400;
}

.portfolioItem {
  height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

img {
  object-fit: cover;
  object-position: center;
}

.ant-btn-icon-only {
  width: fit-content;
}

.ant-btn-lg.ant-btn-block {
  width: 100%;
  margin: 0;
}

.stretchy > img {
  object-fit: contain;
}

.contactPageStyle {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testHearder {
  display: flex;
  padding-top: 16px;
}

.ant-page-header-heading-left {
  margin: 0;
}

.avatar {
  flex: 1;
  max-width: fit-content;
  padding: 0 8px 0 16px;
}
.ant-avatar-image {
  flex-grow: 1;
}

.info {
  flex: 1;
}

.ant-picker-range {
  width: 100%;
}

.divider {
  padding: 0 16px 0 16px;
}

.profile {
  padding: 0 16px 0 16px;
}

.profileCard {
  border-radius: 3px;
}

.ant-form-item-control-input {
  min-height: 0;
}

.ant-form {
  color: inherit;
  font-size: 16px;
}

.links {
  width: 300px;
  text-align: center;
  padding-top: 16px;
}

.anticon {
  font-size: 16px;
}

.link {
  font-size: 24px;
}

.code {
  padding: 16px;
}

.detailsTemplate {
  padding: 0 20px 0 20px;
}

.ant-btn-icon-only {
  height: fit-content;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot)
  .ant-steps-item-icon {
  margin-left: 24px;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 36px;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 70px;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 196px;
  height: 196px;
}
/* LAYOUT */
.menu-body {
  padding: 0 16px 0 16px;
  overflow-y: scroll;
  height: 100%;
  z-index: 1;
}

.layout-body {
  padding: 32px 16px 0px 16px;
  overflow-y: scroll;
  height: 100%;
  z-index: 1;
}

.ant-rate-star:not(:last-child) {
  margin-right: 15px;
}
/* LOADER */
.loader {
  z-index: 1000;
  align-items: center;
  /* background-color: rgba(250, 250, 250, 1); */
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}

/* CARD */

.ant-upload-list-item-card-actions .anticon {
  color: #d11a2a;
}

.service-card-back {
  /* padding: 2px; */
  border-radius: 3px;
  height: fit-content;
}

.card-back {
  padding: 0px;
  border-radius: 3px;
  border: 2px solid #e0e4e4;
  margin: 16px 0 32px;
  height: fit-content;
}

/* ANTD COLOR DEFINITIONS */
/* .ant-rate, .ant-menu-item-icon {
  color: #eb9a92
}

.ant-rate-star-first, .ant-rate-star-second, .ant-menu-item, .ant-tabs, .ant-select-selector, .ant-checkbox-group, .ant-checkbox-wrapper, .ant-form, .ant-input-group, .ant-input, .ant-btn, .ant-btn-dashed, .ant-page-header-heading-title, .ant-page-header-back-button {
  color: #3a3960;
}

*/

.ant-checkbox-wrapper,
.ant-checkbox-group,
.ant-form-item,
.ant-steps-item-title,
.ant-upload-list,
.ant-steps,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: inherit;
}

.ant-switch {
  /* background-color: #e0e4e4; */
  margin: 10px 0;
}

/* .ant-form-item-control {
  align-items: flex-end;
} */

.ant-form-vertical .ant-form-item .ant-form-item-control,
.ant-form-horizontal .ant-form-item-control {
  align-items: initial;
}

/* .ant-switch-checked {
  background-color: #ee9c65;
} */

.floating-action {
  position: absolute;
  padding: 12px;
  border-radius: 3px;
  bottom: -40px;
  right: 12px;
}

.floating-status {
  position: absolute;
  padding: 8px 16px;
  border-radius: 3px;
  bottom: -20px;
  right: 16px;
}

.ant-rate-star-first, .ant-rate-star-second{
  color: #e0e4e4
}

.ant-rate{
  color: #ee9c65
}


/* PICTURE CARD */
.middle-float {
  position: relative;
  padding: 8px 16px;
  border-radius: 3px;
  width: fit-content;
  z-index: 2;
  top: -12px;
  font-weight: 600;
}

.picture-card-title {
  font-size: 32px;
  text-align: start;
  padding: 0 16px 8px 16px;
}

.picture-card-title.gap {
  padding-top: 16px;
}

.picture-card-desc {
  font-size: 16px;
  text-align: start;
  padding: 0 16px 0 16px;
}

.picture-card-inner {
  border-radius: 3px;
  min-width: fit-content;
}

.picture-card-info {
  flex: 1;
  margin-top: -20px;
  min-height: 100%;
  max-height: 100%;
  position: relative;
  padding: 16px 0 32px 0;
}

.picture-card-cover {
  max-width: 100%;
  background: black;
  height: 200px;
  border-radius: 3px 3px 0px 0px;
  min-width: fit-content;
  z-index: 1;
}

/* INFO CARD */

.info-card-title {
  font-size: 32px;
  text-align: start;
  padding: 0 16px 8px 16px;
}

.info-card-inner {
  padding: 16px;
  border-radius: 3px;
}

/* INFO */

.info-text {
  padding-bottom: 8px;
  overflow-wrap: anywhere;
}

/* INDICATOR DOTS */

.indicator-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 3px;
  background-color: grey;
  margin: 7px 5px;
  transition-duration: 300ms;
}

.indicator-wrapper {
  position: absolute;
  width: 100%;
  z-index: 95;
  bottom: 0px;
  text-align: center;
}

/* PROFILE SETTINGS */

.setting-item {
  width: 100%;
  padding: 8px 0 8px 0;
  border-bottom: 1px solid;
}

.setting-title {
  font-size: 20px;
  text-align: start;
}

.setting-button {
  text-align: end;
}

/* LOGIN PAGE */

.login-page {
  display: flex;
  height: fit-content;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 24px;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 30%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    190px 16.33333px #002bff, -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
    118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
    36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
    91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
    141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 4s, 4s, 4s;
  -webkit-animation-delay: 4s, 4s, 4s;
  -o-animation-delay: 4s, 4s, 4s;
  -ms-animation-delay: 4s, 4s, 4s;
  animation-delay: 4s, 4s, 4s;
  -moz-animation-duration: 4s, 4s, 20s;
  -webkit-animation-duration: 4s, 4s, 20s;
  -o-animation-duration: 4s, 4s, 20s;
  -ms-animation-duration: 4s, 4s, 20s;
  animation-duration: 4s, 4s, 20s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.overflowtext {
  animation: leftright 50s infinite linear;
  white-space: nowrap;
}

@keyframes leftright {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-150rem);
  }
}

.self-start {
  --webkit-align-self: "flex-start";
}

.self-end {
  --webkit-align-self: "flex-end";
}
